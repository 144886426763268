import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/dashboard'
    {path: '', pathMatch : 'full', redirectTo: 'dashboard'},

    // Redirect signed in user to the '/dashboard'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'dashboard'},

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule)},
            {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)},
            {path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule)},
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)},
            {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule)}
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule)},
            {path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule)}
        ]
    },

    // Landing routes
    {
        path: '',
        component  : LayoutComponent,
        data: {
            layout: 'empty'
        },
        children   : [
            {path: 'home', loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule)},
        ]
    },

    // Admin routes
    {
        path       : '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component  : LayoutComponent,
        resolve    : {
            initialData: InitialDataResolver,
        },
        children   : [
            {path: 'dashboard', loadChildren: () => import('app/modules/admin/dashboard/dashboard.module').then(m => m.DashboardModule)},
            {path: 'complaints', loadChildren: () => import('app/modules/admin/complaint/complaint.module').then(m => m.ComplaintModule)},
            {path: 'languages', loadChildren: () => import('app/modules/admin/language/language.module').then(m => m.LanguageModule)},
            {path: 'checkouts', loadChildren: () => import('app/modules/admin/checkout/checkout.module').then(m => m.CheckoutModule)},
            {path: 'checkouts/:id', loadChildren: () => import('app/modules/admin/checkout/checkout-detail/checkout-detail.module').then(m => m.CheckoutDetailModule)},
            {path: 'reward', loadChildren: () => import('app/modules/admin/reward/reward.module').then(m => m.RewardModule)},
            {path: 'reward/:id', loadChildren: () => import('app/modules/admin/reward/reward-detail/reward-detail.module').then(m => m.RewardDetailModule)},
            {path: 'loyalty-config', loadChildren: () => import('app/modules/admin/loyalty-config/loyalty-config.module').then(m => m.LoyaltyConfigModule)},
            {path: 'corner-visit', loadChildren: () => import('app/modules/admin/corner-visit/corner-visit.module').then(m => m.CornerVisitModule)},
            {path: 'corner-visit/:id', loadChildren: () => import('app/modules/admin/corner-visit/corner-visit-detail/corner-visit-detail.module').then(m => m.CornerVisitDetailModule)},
            {path: 'corner-admins', loadChildren: () => import('app/modules/admin/corner-visit/corner-admins/corner-admins.module').then(m => m.CornerAdminsModule)},
            {path: 'events', loadChildren: () => import('app/modules/admin/events/events.module').then(m => m.EventsModule)},
            {path: 'app-categories', loadChildren: () => import('app/modules/admin/app-categories/app-categories.module').then(m => m.AppCategoriesModule)},
            {path: 'customerList', loadChildren: () => import('app/modules/admin/customer-list/customer-list.module').then(m => m.CustomerListModule)},
            {path: 'customer/0',   redirectTo: '/dashboard', pathMatch: 'full' },
            {path: 'customer/:id', loadChildren: () => import('app/modules/admin/customer-detail/customer-detail.module').then(m => m.CustomerDetailModule)},
            {path: 'subscription', loadChildren: () => import('app/modules/admin/subscription/subscription-list/subscription-list.module').then(m => m.SubscriptionListModule)},
            {path: 'subscription/:id', loadChildren: () => import('app/modules/admin/subscription/subscription-detail/subscription-detail.module').then(m => m.SubscriptionDetailModule)},
            {path: 'subscription-config', loadChildren: () => import('app/modules/admin/subscription-config/subscription-config.module').then(m => m.SubscriptionConfigModule)},
            {path: 'subscription-dashboard', loadChildren: () => import('app/modules/admin/subscription-dashboard/subscription-dashboard.module').then(m => m.SubscriptionDashboardModule)},
            {path: 'products-info', loadChildren: () => import('app/modules/admin/products/products.module').then(m => m.ProductsModule)},
            {path: 'products-info/:id', loadChildren: () => import('app/modules/admin/products/product-detail/product-detail.module').then(m => m.ProductDetailModule)},
            {path: 'products-info/new', loadChildren: () => import('app/modules/admin/products/product-detail/product-detail.module').then(m => m.ProductDetailModule)},
            {path: 'restaurants', loadChildren: () => import('app/modules/admin/restaurant/restaurants.module').then(m => m.RestaurantsModule)},
            {path: 'restaurants/:id', loadChildren: () => import('app/modules/admin/restaurant/restaurant-detail/restaurant-detail.module').then(m => m.RestaurantDetailModule)},
            {path: 'restaurants', loadChildren: () => import('app/modules/admin/restaurant/restaurant-detail/restaurant-detail.module').then(m => m.RestaurantDetailModule)},
        ]
    }
];
