/* tslint:disable:max-line-length */
import { Inject, Injectable, Injector } from '@angular/core';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { TranslocoService, TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { AppInjector } from 'app/app.module';


export const defaultNavigation: FuseNavigationItem[] = [  
    {
        id      : 'general',
        title: "GENERAL",
        type    : 'collapsable',
        children: [
            {
                id   : 'productInfo',
                title: "Productos",
                type : 'basic',
                icon : 'heroicons_outline:tag',
                link : '/products-info'
            },
            {
                id   : 'restaurants',
                title: "Restaurantes",
                type : 'basic',
                icon : 'mat_outline:food_bank',
                link : '/restaurants'
            },
        ]
    },
    {
        id      : 'corner',
        title: "CORNER",
        type    : 'collapsable',
        children: [
            {
                id   : 'dashboard',
                title: "Resumen",
                type : 'basic',
                icon : 'heroicons_outline:chart-pie',
                link : '/dashboard'
            },
            {
                id   : 'cornerVisits',
                title : "Visitas corner",
                type : 'basic',
                icon : 'heroicons_outline:shopping-bag',
                link : '/corner-visit'
            },
            {
                id   : 'checkouts',
                title : "Compras corner",
                type : 'basic',
                icon : 'heroicons_outline:shopping-cart',
                link : '/checkouts'
            },
            {
                id   : 'events',
                title : "Eventos",
                type : 'basic',
                icon : 'heroicons_outline:document-report',
                link : '/events'
            },
            {
                id   : 'complaints',
                title : "Quejas",
                type : 'basic',
                icon : 'heroicons_outline:emoji-sad',
                link : '/complaints'
            },
            {
                id   : 'customerList',
                title : "Lista de usuarios",
                type : 'basic',
                icon : 'heroicons_outline:clipboard-list',
                link : '/customerList'
            },
            {
                id   : 'corner-admins',
                title : "Administradores Corner",
                type : 'basic',
                icon : 'heroicons_outline:user-group',
                link : '/corner-admins'
            }
        ]
    },
    {
        id      : 'loyalty',
        title : "LOYALTY",
        type    : 'collapsable',
        children: [
            {
                id   : 'rewards',
                title : "Challenges",
                type : 'basic',
                icon : 'heroicons_outline:star',
                link : '/reward'
            },
            {
                id   : 'configuration',
                title : "Configuración",
                type : 'basic',
                icon : 'heroicons_outline:cog',
                link : '/loyalty-config'
            },
        ]
    },
    {
        id      : 'app',
        title : "APP",
        type    : 'collapsable',
        children: [
            {
                id   : 'categories',
                title : "Categorías",
                type : 'basic',
                icon : 'mat_outline:category',
                link : '/app-categories'
            },
            {
                id   : 'languages',
                title: "Traducciones",
                type : 'basic',
                icon : 'heroicons_outline:translate',
                link : '/languages'
            },
        ]
    },
    {
        id      : 'subscription',
        title : "SUBSCRIPTION",
        type    : 'collapsable',
        children: [
            {
                id   : 'subscription.dashboard',
                title : "Subcription dashboard",
                type : 'basic',
                icon : 'heroicons_outline:chart-pie',
                link : '/subscription-dashboard',
                badge: {
                    title  : '',
                    classes: ''
                }
            },
            {
                id   : 'subscription',
                title : "Subcription List",
                type : 'basic',
                icon : 'mat_outline:subscriptions',
                link : '/subscription'
            },
            {
                id   : 'configuration',
                title : "Configuración",
                type : 'basic',
                icon : 'heroicons_outline:cog',
                link : '/subscription-config'
            }
        ]
    },
    {
        id  : 'divider-1',
        type: 'divider'
    },
    {
        id   : 'sign-out',
        title : "Cerrar sesión",
        type : 'basic',
        icon : 'heroicons_outline:logout',
        link : '/sign-out'
    }
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
