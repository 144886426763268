import { Component, OnInit } from '@angular/core';
import { InfoBannerDto } from 'app/core/admin-api/info-banner/info-banner.types';
import { InfoBannerService } from 'app/core/admin-api/info-banner/info-banner.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-info-banner',
  templateUrl: './info-banner.component.html',
  styleUrls: ['./info-banner.component.scss']
})
export class InfoBannerComponent implements OnInit {

  public infoBanner: InfoBannerDto;
  public dataSource: any;
  public isShow: string = 'false';
  public infoBannerMessage: string;
  public newInfoBannerMessage: string;
  public closed: string;
  public bannerMessage: string;
  public bannerId: string;
  constructor(
    private _infoBannerService: InfoBannerService,
    private _translocoService: TranslocoService
  ) { }
  

 
  
  ngOnInit(): void {
    if(this.getBannerState() == null || this.getMessage() == null || this.getBannerId() == null){
      this.setDefaultLocalStorageValues();
    }
   
    this.getChangedLanguage();
   
    this._translocoService.langChanges$.subscribe((activeLang) => {

      // Get the active lang
      this.getChangedLanguage();
  });
   
  }
  getChangedLanguage(){
    let oldMessage = this.getMessage();
    let oldId = this.getBannerId();
    let state = this.getBannerState();

    if(oldMessage){
      this.bannerMessage = oldMessage;
    }
    if(oldId){
      this.bannerId = oldId;
    }
    if(state == 'true'){
      this.toggleDisplayOn();
    }
    this._infoBannerService.getInfoBannerById(null).subscribe((data) => {
      if(this.isShow == 'true' || (this.isShow == 'false' && data.id.toString() != oldId) ){
        this.infoBanner = data;
        this.setState('true');
        this.setMessage(this.infoBanner.message);
        this.setBannerId(this.infoBanner.id.toString());
      }
  });
  }
  setDefaultLocalStorageValues() : void{
    localStorage.setItem('InfoBannerState', null);
    localStorage.setItem('InfoBannerMessage', null);  
    localStorage.setItem('InfoBannerId', null);  
  }

  getBannerState(): string{
    return localStorage.getItem('InfoBannerState');  
  }
  toggleDisplayOn() {
    this.isShow = 'true';
    this.setState('true');
  }
  toggleDisplayOff() {
    this.isShow = 'false';
    this.setState('false');
    this.setBannerId(this.bannerId);
  }

  setState(state: string){
    this.isShow = state;
    localStorage.setItem('InfoBannerState', state) 
  }
  setMessage(message: string){
    this.bannerMessage = message;
    return localStorage.setItem('InfoBannerMessage',message );  

  }
  setBannerId(id: string){
    this.bannerId = id;
    return localStorage.setItem('InfoBannerId',id );  

  }
  getMessage(){
    return localStorage.getItem('InfoBannerMessage');  
  }
  getBannerId(){
    return localStorage.getItem('InfoBannerId');  
  }
  
}