import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { take } from 'rxjs';
import { AvailableLangs, TranslocoService } from '@ngneat/transloco';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';

@Component({
    selector       : 'languages',
    templateUrl    : './languages.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs       : 'languages'
})
export class LanguagesComponent implements OnInit, OnDestroy
{
    availableLangs: AvailableLangs;
    activeLang: string;
    flagCodes: any;

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _translocoService: TranslocoService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Get the available languages from transloco
        this.availableLangs = this._translocoService.getAvailableLangs();

        // Subscribe to language changes
        this._translocoService.langChanges$.subscribe((activeLang) => {

            // Get the active lang
            this.activeLang = activeLang;

            // Update the navigation
            this._updateNavigation(activeLang);
        });

        // Set the country iso codes for languages for flags
        this.flagCodes = {
            'en': 'us',
            'tr': 'tr',
            'es': 'es'
        };
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set the active lang
     *
     * @param lang
     */
    setActiveLang(lang: string): void
    {
        // Set the active lang
        this._translocoService.setActiveLang(lang);
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any
    {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the navigation
     *
     * @param lang
     * @private
     */
    private _updateNavigation(lang: string): void
    {
        // For the demonstration purposes, we will only update the Dashboard names
        // from the navigation but you can do a full swap and change the entire
        // navigation data.
        //
        // You can import the data from a file or request it from your backend,
        // it's up to you.

        // Get the component -> navigation data -> item
        const navComponent = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>('mainNavigation');

        // Return if the navigation component does not exist
        if ( !navComponent )
        {
            return null;
        }

        // Get the flat navigation data
        const navigation = navComponent.navigation;

        //translate the navigation data

        for ( let item of navigation )
        {
            if(item.children){
                item.children.forEach(element => {
                    this._translocoService.selectTranslate("navigation."+element.id).subscribe(data => {
                        element.title = data;
                        navComponent.refresh()
                    })
                });
            }else{
                this._translocoService.selectTranslate("navigation."+item.id).subscribe(data => {
                    item.title = data;
                    navComponent.refresh()
                })
            }
        }

        // Get the Project dashboard item and update its title
        const projectDashboardItem = this._fuseNavigationService.getItem('dashboards.project', navigation);
        if ( projectDashboardItem )
        {
            this._translocoService.selectTranslate('Project').pipe(take(1))
                .subscribe((translation) => {

                    // Set the title
                    projectDashboardItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }

        // Get the Analytics dashboard item and update its title
        const analyticsDashboardItem = this._fuseNavigationService.getItem('dashboards.analytics', navigation);
        if ( analyticsDashboardItem )
        {
            this._translocoService.selectTranslate('Analytics').pipe(take(1))
                .subscribe((translation) => {

                    // Set the title
                    analyticsDashboardItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }
        ///////////////////////////////// 
        
        // Get the Main Dashboard - dashboard item and update its title
        const dashboardDashboardItem = this._fuseNavigationService.getItem('dashboard', navigation);
        if ( dashboardDashboardItem )
        {
            this._translocoService.selectTranslate('navigation.dashboard').pipe(take(1))
                .subscribe((translation) => {

                    // Set the title
                    dashboardDashboardItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }

        // Get the Main Dashboard - dashboard item and update its title
        const translationsdDashboardItem = this._fuseNavigationService.getItem('languages', navigation);
        if ( translationsdDashboardItem )
        {
            this._translocoService.selectTranslate('navigation.translations').pipe(take(1))
                .subscribe((translation) => {

                    // Set the title
                    translationsdDashboardItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }

        // + CORNER + 
        // Get the CornerVisitrs dashboard item and update its title
        const cornerVisitsDashboardItem = this._fuseNavigationService.getItem('corner.cornerVisits', navigation);
        if ( cornerVisitsDashboardItem )
        {
            this._translocoService.selectTranslate('navigation.corner-visits').pipe(take(1))
                .subscribe((translation) => {

                    // Set the title
                    cornerVisitsDashboardItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }
        
        // Get the Checkout dashboard item and update its title
        const checkoutsDashboardItem = this._fuseNavigationService.getItem('corner.checkouts', navigation);
        if ( checkoutsDashboardItem )
        {
            this._translocoService.selectTranslate('navigation.corner-checkouts').pipe(take(1))
                .subscribe((translation) => {

                    // Set the title
                    checkoutsDashboardItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }

        // Get the Events dashboard item and update its title
        const eventsDashboardItem = this._fuseNavigationService.getItem('corner.events', navigation);
        if ( eventsDashboardItem )
        {
            this._translocoService.selectTranslate('navigation.corner-events').pipe(take(1))
                .subscribe((translation) => {

                    // Set the title
                    eventsDashboardItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }

        // Get the Complaints dashboard item and update its title
        const complaintsDashboardItem = this._fuseNavigationService.getItem('corner.complaints', navigation);
        if ( complaintsDashboardItem )
        {
            this._translocoService.selectTranslate('navigation.corner-complaints').pipe(take(1))
                .subscribe((translation) => {

                    // Set the title
                    complaintsDashboardItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }

         // -CORNER- 

         // +LOYALTY+

         // Get the Rewards dashboard item and update its title
        const challengesDashboardItem = this._fuseNavigationService.getItem('loyalty.challenges', navigation);
        if ( challengesDashboardItem )
        {
            this._translocoService.selectTranslate('navigation.loyalty-challenges').pipe(take(1))
                .subscribe((translation) => {

                    // Set the title
                    challengesDashboardItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }

        // Get the Challenges Configuration dashboard item and update its title
        const challengesConfigurationDashboardItem = this._fuseNavigationService.getItem('loyalty.configuration', navigation);
        if ( challengesConfigurationDashboardItem )
        {
            this._translocoService.selectTranslate('navigation.loyalty-config').pipe(take(1))
                .subscribe((translation) => {

                    // Set the title
                    challengesConfigurationDashboardItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }
         // -LOYALTY-

         // +APP+

         // Get the Categories dashboard item and update its title
        const categoriesConfigurationDashboardItem = this._fuseNavigationService.getItem('app.categories', navigation);
        if ( categoriesConfigurationDashboardItem )
        {
            this._translocoService.selectTranslate('navigation.app-categories').pipe(take(1))
                .subscribe((translation) => {

                    // Set the title
                    categoriesConfigurationDashboardItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }
         // -APP-

         // Get the Log out dashboard item and update its title
        const logoutConfigurationDashboardItem = this._fuseNavigationService.getItem('sign-out', navigation);
        if ( logoutConfigurationDashboardItem )
        {
            this._translocoService.selectTranslate('navigation.log-out').pipe(take(1))
                .subscribe((translation) => {

                    // Set the title
                    logoutConfigurationDashboardItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }
    }
}
