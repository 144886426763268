<div class="flex-auto p-2 sm:p-10 bg-indigo-700 inline-flex w-full" *ngIf="isShow =='true'"  >
    <div class="flex items-center pl-2 ml-auto"> 
        <span class="inline-block align-middle text-white text-center
         max-w-50 sm:max-w-md md:max-w-lg break-words"> {{bannerMessage}}
        </span>
    </div>
    <div class="flex items-center pl-2 ml-auto" >
        <button mat-mini-fab color="warn" (click)="toggleDisplayOff()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
