import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { TranslocoService } from '@ngneat/transloco';
import { defaultNavigation } from './mock-api/common/navigation/data';

@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss']
})
export class AppComponent 
{
    constructor(private titleService: Title, private transloco: TranslocoService, private navigationService: FuseNavigationService){   }

    ngOnInit(): void 
	{
       
    }

}
