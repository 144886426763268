import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { InfoBannerDto } from '../info-banner/info-banner.types';

import { AvailableLangs, TranslocoService } from '@ngneat/transloco';



@Injectable({
  providedIn: 'root'
})
export class InfoBannerService {

    constructor(
      private _httpClient: HttpClient,
      private _translocoService: TranslocoService
      ) { }



    private _apiURL : string = environment.apiURL;
    private _controller : string = "/admin/info-banner";

    availableLangs: AvailableLangs;
    activeLang: string;
    getInfoBannerById( language: string)
    {
      this.getLanguage(this.activeLang);
        return this._httpClient.get<InfoBannerDto>(this._apiURL + this._controller + "?language="+this.activeLang);
    }

    getLanguage(currentLanguage: string){

      
      // Get the available languages from transloco
      this.availableLangs = this._translocoService.getAvailableLangs();

      // Subscribe to language changes
      this._translocoService.langChanges$.subscribe((activeLang) => {

          // Get the active lang
          this.activeLang = activeLang;
          return currentLanguage = this.activeLang;
      });
    }
    
}
